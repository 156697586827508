import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 19",
  week: "Semana 3",
  month: "abr",
  day: "01",
  monthNumber: "04",
  date: "2020-04-01",
  path: "/cronologia/semana-03#dia-01-abr",
};

const Day19 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModTwoCols src="/images/svg/01_abr_dinero.svg" alt="" small={false}>
          El Gobierno y las comunidades autónomas también acuerdan la
          distribución de{" "}
          <strong>
            1.048 millones de euros para políticas activas de empleo
          </strong>
          . Un reparto que queda condicionado a una valoración final tras la
          crisis sanitaria.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/01_abr_estudio_movilidad.svg"
          alt=""
          small={false}
        >
          La Secretaría de{" "}
          <strong>Estado de Digitalización e Inteligencia Artificial</strong>
          ha impulsado, en colaboración con el{" "}
          <strong>Instituto Nacional de Estadística</strong> (INE), un{" "}
          <strong>estudio de movilidad</strong> para analizar datos anónimos y
          agregados de desplazamientos de la población{" "}
          <strong>(DataCOVID)</strong>, mientras dure la emergencia sanitaria y
          durante el tiempo necesario hasta que se restablezca la normalidad,
          para contribuir así a una toma de decisiones más eficiente basada en
          los datos.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/01_abr_plataforma_tematica.svg"
          alt="Plataforma Temática Interdisciplinar (PTI) denominada Salud Global/Global Health"
          small={false}
        >
          El Consejo Superior de Investigaciones Científicas (CSIC) ha puesto en
          marcha una{" "}
          <strong>
            Plataforma Temática Interdisciplinar (PTI) denominada Salud
            Global/Global Health
          </strong>
          , en la que colaboran más de 150 grupos de investigación para abordar
          los retos que plantea la epidemia del coronavirus SARS-CoV-2. Por el
          momento, cuenta ya con 12 proyectos científicos.
        </ModTwoCols>
        <ModText>
          Asimismo, el <strong>proyecto de investigación europeo CoNVat</strong>
          , liderado por el Instituto Catalán de Nanociencia y Nanotecnología,
          está diseñando un nuevo sistema de diagnóstico de COVID19 a través de
          una plataforma biosensora basada en nanotecnología óptica, capaz de
          determinar en menos de media hora la presencia de coronavirus en una
          persona.
        </ModText>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-02"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 102.136
          casos (7.719 más que el día anterior), 9.053 fallecidos (8.9% de los
          casos positivos) y 22.647 curados (22,2% de los casos positivos). El
          total de personas en UCI asciende a 5.872.
        </ModText>
        <ModTwoCols
          src="/images/svg/01_abr_mascarillas.svg"
          alt="mascarillas, guantes, batas, gorros"
          small={true}
        >
          Desde el pasado 10 de marzo, el Gobierno ha distribuido, a través del
          Ministerio de Sanidad,{" "}
          <strong>
            15 millones de mascarillas, 16 millones de guantes, 77.000 batas
            desechables, 83.900 buzos y 408.000 calzas, delantales o gorros
          </strong>
          , entre las comunidades autónomas.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/01_abr_agricultores.svg"
          alt="agricultores y ganaderos, cooperativas, industria agroalimentaria"
          small={true}
        >
          Dada la dimensión de la crisis,{" "}
          <strong>
            España ha solicitado a la Comisión Europea medidas de apoyo a los
            sectores más afectados
          </strong>
          por la crisis de la COVID-19, para facilitar la tarea de agricultores
          y ganaderos, cooperativas, industria agroalimentaria y distribución,
          considerados como sectores esenciales para garantizar el suministro
          alimentario a todos los ciudadanos.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/01_abr_pago_prestaciones.svg"
          alt="adelanto del pago de las prestaciones por desempleo"
          small={true}
        >
          Por su parte, el Ministerio de Trabajo y Economía Social, a través del
          Servicio Público de Empleo Estatal (SEPE), ha llegado a un acuerdo con
          entidades bancarias para que{" "}
          <strong>adelanten el pago de las prestaciones por desempleo</strong>,
          y con ello garantizar liquidez en este contexto de crisis sanitaria y
          económica.
        </ModTwoCols>
        <ModText>
          Y además, tambien ha destinado 236 millones de euros para la
          contratación de trabajadores del campo, que hayan terminado las
          campañas agrícolas, en los ayuntamientos. El objetivo es la
          recuperación de estas poblaciones tras la crisis del COVID19.
        </ModText>
        <ModImage
          src="/images/svg/01_abr_plan_contingencia.svg"
          alt="Plan de Contingencia contra la violencia de género"
        />
        <ModText>
          El <strong>Ministerio de Igualdad</strong> continúa con el{" "}
          <strong>Plan de Contingencia contra la violencia de género</strong>
          que aprobó el pasado mes de marzo tras declarar el estado de alarma.
          Del 14 al 29 de marzo, las llamadas al 016 han aumentado un 12,43% y
          las consultas online un 269,57%, en relación al mismo periodo del año
          anterior.
        </ModText>
        <ModTwoCols
          src="/images/svg/01_abr_repositorio.svg"
          alt="repositorio colaborativo de recursos on line"
          small={true}
        >
          El <strong>Instituto Nacional de Administración Pública</strong>
          (INAP) lanza en su nueva web un{" "}
          <strong>repositorio colaborativo de recursos on line</strong>, con el
          objetivo de facilitar el acceso a múltiples recursos en abierto para
          el aprendizaje permanente, especialmente destinados a empleadas y
          empleados públicos, y a todas las personas interesadas en la
          Administración Pública.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/01_abr_at_psicologica.svg"
          alt="servicio de atención emocional y psicológica"
          small={true}
        >
          El servicio de atención emocional y psicológica vía Whatsapp ha
          recibido un total de 168, desde que el pasado 21 de marzo se pusiera
          en funcionamiento.
        </ModTwoCols>
        <ModText>
          Por último, y en paralelo a la información suministrada por el
          Ministerio de Sanidad, el Boletín Oficial del Estado ha recogido en un
          apartado específico toda la normativa relativa a la COVID19.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.mitma.es/transporte-terrestre/punto-de-informacion-de-servicios-de-restauracion"
            name="Punto de información de servicios de restauración"
          />
          <ReferenceRow
            link="https://www.mitma.es/transporte-terrestre/punto-de-informacion-de-servicios-de-restauracion"
            name="Boletín Oficial del Estado: Normativa relativa a
            COVID19"
          />
          <ReferenceRow
            link="https://sites.google.com/inap.info/enabierto/"
            name="Repositorio de recursos en abierto procedentes de
            Instituciones, Entidades y Administraciones Públicas"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day19;
